::placeholder {
    font-size: 10px;
    vertical-align: 'center';
}

.input-read {
    width: 45px;
    height: 45px;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #FFFFFF00;
    /* border: 1px solid blue; */
    border-radius: 3px;
    outline: 1px solid none;
    cursor: auto;
}

.input-read-sm {
    width: 35px;
    height: 35px;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #FFFFFF00;
    /* border: 1px solid lime; */
    border-radius: 3px;
    outline: 1px solid none;
    cursor: auto;
}

.unit-box {
    font-size: 18px;
    /* border: 1px solid red; */
    font-weight: 600;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;

}

.input-write {
    width: 45px;
    height: 45px;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    /* border: 1px solid #FFFFFF00; */
    border: 1px solid var(--active-color);
    background-color: transparent;
}

.input-write-sm {
    /* width: 30px; */
    width: 45px;

    height: 30px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    /* border: 1px solid #FFFFFF00; */
    border: 1px solid var(--active-color);
    background-color: transparent;
}

.input-write-sm-no-border {
    width: 30px;
    height: 30px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    /* border: 1px solid #FFFFFF00; */
    /* border: 1px solid var(--active-color); */
    background-color: transparent;
}

.single-line-input {
    width: 50px;
    border: none;
    border-bottom: 1px solid var(--active-color);

}

.single-line-input-without-width {
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--active-color);

}

.single-line-input:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid var(--active-color);

}

.single-line-input-without-width:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid var(--active-color);

}

input[type='input']:focus {
    outline: 1.5px solid red;
    border: 1.5px solid red;
    background: lightgray;

}

.input-write:focus {
    /* border: 2px solid #6F5193; */
    /* outline: 1.5px solid #6F5193; */
    outline: 1.5px solid var(--active-color);
    /* outline: 1.5px solid red; */

    /* outline: 1.5px solid var(--active-color); */


    /* background-color: wheat; */
}

.value-checker:focus {
    /* outline: 1.5px solid #6F5193; */
    outline: 1.2px solid var(--active-color);

}

.text-checker:focus {
    /* outline: 1.5px solid #6F5193; */
    outline: 1.5px solid var(--active-color);

}

input:disabled {
    background-color: #FFFFFF00;
}

/* .dark-mode{
    height: 10%;
    width: 100%;
    background-color: black;

} */

.text-amber {
    /* color: #FFBF00; */
    color: #FFAA33;
}

.text-lavender {
    color: #6F5193;
}

@font-face {
    font-family: 'kalpurush';
    src: url(./assets/font/kalpurush.ttf);
}

@font-face {
    font-family: 'Nikosh';
    src: url(./assets/font/Nikosh.ttf);
}

@font-face {
    font-family: 'BenSen';
    src: url(./assets/font/BenSen.ttf);
}

@font-face {
    font-family: 'Bengla-academy';
    src: url(./assets/font/Bangla.ttf);
}

.fnt-kalpurush {
    font-family: 'nik';
}

.fnt-Nikosh {
    font-family: 'Kalpurush';
}

.active-bangla-font {
    font-family: 'Bengla-academy';
    font-size: 19px;
}

.fnt-inst {
    font-size: 16.5px;
    font-weight: 800;
}

.b-mnGreen {
    background-color: #004953
}

.txt-mnGreen {
    color: #004953
}

.home {
    height: calc(100%-50px);
    width: 100%;
    overflow-y: auto;
}


.items {
    width: 150px;
    height: 100px;
    border: 1px solid lavender;
    border-radius: 5px;
    box-shadow: 25px;
    /* text-shadow: 50px; */
    font-size: 17px;
    color: royalblue;
    cursor: pointer;
    background-color: white;
}

/* .items:hover {
    text-decoration: overline;
} */

.back-icon {
    transform: rotate(-90deg);
}

.menu-icon {
    fill: none;
    stroke-width: 2px;
    stroke: red;
}


#clockContainer {
    position: relative;
    margin: auto;
    height: 150px;
    width: 150px;
    /* height: 40vw; */
    /*to make the height and width responsive*/
    /* width: 40vw; */
    background: url('./img/clock-300x300.jpg') no-repeat;
    /*setting our background image*/
    background-size: 100%;
    /* z-index: 10; */
}

#hour,
#minute,
#second {
    position: absolute;
    background: black;
    border-radius: 10px;
    transform-origin: bottom;
}

#hour {
    width: 1.8%;
    height: 25%;
    top: 25%;
    left: 48.85%;
    opacity: 0.8;
}

#minute {
    width: 1.6%;
    height: 30%;
    top: 19%;
    left: 48.9%;
    opacity: 0.8;
}

#second {
    /* width: 1%; */
    width: 0.1%;
    height: 40%;
    top: 9%;
    left: 49.25%;
    opacity: 0.8;
}

/* .petals {
    padding-left: 20px;
    width: 200px;
    height: 100px;
    border: 1px solid lavender;
    border-radius: 60%;
    background-color: lavender;

}

:root {
    --center-top: 30%;
    --center-left: 15%;
} */

/* /* .center {
    position: absolute;
    top: var(--center-top);
    left: var(--center-left);
    width: 70px;
    height: 70px;
    border: 1px solid gold;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-left: 150px;
    z-index: 10;
    background-color: #6F5193;
    color: white;
    font-size: 22px;
    padding-top: 15px;

} */

.petal1 {
    position: absolute;
    top: calc(var(--center-top) - 110px);
    left: calc(var(--center-left) - 5px);
    transform: rotate(45deg);
}

.petal2 {
    position: absolute;
    top: calc(var(--center-top) - 110px);
    left: calc(var(--center-left) + 170px);
    transform: rotatez(-45deg);
}

.petal3 {
    position: absolute;
    top: calc(var(--center-top) + 85px);
    left: calc(var(--center-left) + 169px);
    transform: rotatez(-305deg);

}

.petal4 {
    position: absolute;
    top: calc(var(--center-top) + 85px);
    left: calc(var(--center-left) - 0px);
    transform: rotate(300deg);
}


.ball-num {
    font-weight: 700;
    text-align: center;
}

.fraction-container {
    display: flex;
    flex-direction: column;
}

.sign {
    padding: 0 !important;
    margin: 0 !important;
}

.ball {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    /* background: linear-gradient(#3378FF, #9442FE); */
    /* background: linear-gradient(#C33764,#1D2671); */
    /* background: linear-gradient(#D4145A,#FBB03B); */
    background: red;
    cursor: pointer;
}

.key {
    padding: 10px;
    background-color: aliceblue;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    cursor: pointer;
}

.keyboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 50px;
}

.key-row {
    width: 100%;
}

.keyboard-class {
    background-color: red;
}

.modal-class {
    /* background-color: #FFAA33; */
    width: 300px;
    /* height: 230px; */
    height: 220px;

    z-index: 100;
    position: absolute;
    top: 85px;
    left: calc(50% - 150px);
    /* border: 5px solid red; */
    /* overflow-y: scroll; */
}

.cursor-pointer {
    cursor: pointer;
}

/* .c {
    overflow: auto;
} */


.multiplication-sign-hands {
    height: 13px;
    width: 0;
    margin: 2px;
}

.multiplication-sign-hands1 {
    position: relative;
    top: 8px;
    left: 0;
    transform: rotate(38deg);
}

.multiplication-sign-hands2 {
    position: relative;
    top: -7px;
    left: 0;
    transform: rotate(322deg);
}

.division-sign-hand {
    height: 13px;
    width: 0;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1px;
    transform: rotate(90deg);
}

.division-sign-point {
    height: 5px;
    width: 5px;
    border-radius: 50%;
}

.site-name {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 23px;
    font-weight: 600;
    /* font-style: oblique; */
}

.menu-items {
    margin-top: 3px;
}

.app-bar {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.app-name {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.app-menu-items {
    margin-top: 4px;
}

.app-design-div {
    width: 100%;
    height: 130px;
    border-radius: 0 0 50% 50%;
    margin-top: 62px;
    /* border: 1px solid white; */
    /* background-color: #6F5193; */
}

.app-container {
    position: fixed;
    top: 62px;
    left: 0px;
    width: 100%;
    /* height: calc(100% - 96px); */
    height: calc(100% - 50px);
    background-color: transparent;
    overflow-y: auto;
    /* border: 1px solid red; */
    /* background-color: #FFAA33; */
    z-index: 0;
}

.blur-effect {
    filter: blur(5px);
}

/* .app-bottom-bar {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 40px;
} */

.app-menu-bar {
    width: calc(100% - 20px);
    height: 35px;

}

.bottom-menu-items {
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}

.link-container {
    height: 80px;
    width: 150px;
    background-color: white;
}

.app-setting-modal {
    position: fixed;
    bottom: 39px;
    left: 0px;
    width: 100%;
    height: 150px;
}

.app-heading {
    font-size: 20px;
}

.incomplete-sentence {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/* Ascending css */
.common-margin-padding-for-ascending-descending {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ascending-descending-two-digit {
    padding-left: 20px;
    padding-right: 20px;
}

.ascending-descending-three-digit {
    padding-left: 15px;
    padding-right: 15px;
}

.ascending-descending-four-digit {
    padding-left: 10px;
    padding-right: 10px;
}

.ascending-descending-five-digit {
    padding-left: 5px;
    padding-right: 5px;
}

.scroll-test {
    height: 100px;
    width: 500px;
    overflow: auto;
}

.visibility-hidden {
    visibility: hidden;
}

.v-none {
    visibility: hidden;
}

/* .bengali-placeholder::placeholder {
    font-family: 'fnt-Nikosh';
}

input::placeholder {
    font-family: 'fnt-Nikosh';
} */