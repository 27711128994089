.AppNavigation-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    /* background: rgba(255, 255, 255, 0); */
    /* box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37); */
    /* backdrop-filter: blur(20px); */
    /* border: 1px solid rgba(255,255,255,0.18); */
    /* margin-bottom: 10px; */

}

/* .content-box-app {
    height: calc(100% -var(--header-height));
    margin-top: 60px;
    overflow: auto;
    background-color: white;

    border: 1px solid green;
} */

.content-box-app {
    position: fixed;
    top: 20px;
    left: 0;
    height: calc(100% - var(--header-height));
    width: 100%;
    margin-top: 40px;
    margin-left: 0px;
    /* margin-left: 250px; */
    overflow: auto;
}