:root {
  --thumb-width: 18px;
  --thumb-height: 18px;
  --color: #d0c8c8;
  --color1: #0039A6;
  --color2: #f80303;
}

.slidecontainer {
  width: 100%;
  /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 5px;
  /* Specified height */
  background: #c9b4b4;
  /* background: var(--color1); */

  /* Grey background */
  border-radius: 15px;
  outline: none;
  /* Remove outline */
  /* opacity: 0.7; */
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;

}

/* Mouse-over effects */
.slider:hover {
  opacity: 50%;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: var(--thumb-width);
  /* Set a specific slider handle width */
  height: var(--thumb-height);
  /* Slider handle height */
  border-radius: 50%;
  /* background: #04AA6D; Green background */
  background-color: var(--color2);
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: var(--thumb-width);
  /* Set a specific slider handle width */
  height: var(--thumb-height);
  /* Slider handle height */
  background: #04AA6D;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}