:root {
    --header-height: 60px;
    --menu-width: 250px;
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    z-index: 10;
    font-size: 25px;
    color: #fff;
    padding-left: 20px;
    padding-top: 10px;
    text-align: center;
}

#sidebarMenu {
    height: calc(90%);
    /* max-height: calc(90%); */
    position: fixed;
    left: 250px;
    top: 0;
    width: var(--menu-width);
    margin-top: 60px;
    background-color: white;
    overflow: auto;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;

    /* background-color: aqua; */
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    color: #fff;
    border-bottom: 1px solid rgba(255, 55, 25, 0.10);
}

.menu li a {
    color: var(--active-color);
    display: block;
    text-decoration: none;
    padding: 10px;
}

.content-box-web {
    /* height: 100%; */
    height: calc(100% -var(--header-height));
    margin-top: 37px;
    margin-left: 250px;
    overflow: auto;
    /* background-color: white; */
    /* background-color: brown; */

    /* border: 1px solid red; */
}

.active {
    font-size: 16.5px;
    font-weight: 600;
}

@media screen and (max-width:576px) {

    .header {
        text-align: start;
    }

    .sidebar-icon-toggle {
        width: 22px;
        height: 22px;
        position: fixed;
        top: 22px;
        left: 90%;
        cursor: pointer;
        z-index: 20;
    }

    #sidebarMenu {
        height: calc(80% -000px);
        position: fixed;
        left: calc(100% + 250px);
        top: 0;
        width: 250px;
        margin-top: 60px;
        transform: translateX(-250px);
        transition: transform 250ms ease-in-out;
        z-index: 50;
    }

    #open-sidebar-menu:checked~#sidebarMenu {
        transform: translateX(-500px)
    }

    .spinner {
        height: 3px;
        background-color: #fff;
        transition: all 0.3s;
    }

    .spinner.middle,
    .spinner.bottom {
        margin-top: 3px;
    }

    #open-sidebar-menu:checked~.sidebar-icon-toggle>.spinner.middle {
        opacity: 0;
    }

    #open-sidebar-menu:checked~.sidebar-icon-toggle>.spinner.top {
        transform: rotate(135deg);
        margin-top: 8px;
    }

    #open-sidebar-menu:checked~.sidebar-icon-toggle>.spinner.bottom {
        transform: rotate(-135deg);
        margin-top: -9px;
    }

    .content-box-web {
        position: fixed;
        top: 20px;
        left: 0;
        height: calc(100% - var(--header-height));
        width: 100%;
        margin-top: 40px;
        margin-left: 0px;
        overflow: auto;
    }
}