:root {
    --lavendar: #6F5193;
    --jarcanda: #35407D;
    --blue: #0047AB;
    --blue1: #4169E1;
    --blue2:#0039A6;
    --jade-green:#00A36C;
    --jangle-green:#2AAA8A;
    --test-green:#2e8b57;



    /* --active-color: #7c5aa4; */
    --active-color:var(--blue2);
    /* --active-color:var(--blue); */

    --light-blue: #6495ED;

    --active-light-color: #CCCCFF
}

.btn-custom {
    background-color: var(--active-color);
    border: 1px solid var(--active-color);
    border-radius: 5px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 14px;
    /* color: white; */
}

.text-custom {
    color: var(--active-color);
}

.bg-custom {
    background-color: var(--active-color);
}
.bg-custom1 {
    background-color: var(--light-blue);
}

.border-custom {
    border: 1px solid var(--active-color);
}

.border-top-custom {
    border-top: 1px solid var(--active-color);
}
